import { useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';


import GuestLayout from '@/Layouts/GuestLayout';
import { Head, Link, useForm } from '@inertiajs/react';

export default function Login({ status, errors, canResetPassword }) {
    const { data, setData, post, processing, reset } = useForm({
        email: '',
        password: '',
        remember: false,
    });

    useEffect(() => {
        return () => {
            reset('password');
        };
    }, []);

    const submit = (e) => {
        e.preventDefault();

        post(route('login'));
    };

    return (
        <GuestLayout>
            <Head title="Log in" />
            <h2 className='mt-5'>Login</h2>
            {status &&

                <Alert variant="success">
                    {status}
                </Alert>
            }
            <Form onSubmit={submit} className="w-100 ">
                <p>
                    <Form.Group controlId="email">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                            type="email"
                            value={data.email}
                            autoComplete="username"
                            isFocused={true}
                            onChange={(e) => setData('email', e.target.value)}
                        />
                        {errors.email &&
                            <Alert variant="danger">
                                {errors.email}
                            </Alert>
                        }
                    </Form.Group>
                </p>
                <p>
                    <Form.Group controlId="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            value={data.password}
                            autoComplete="username"
                            isFocused={true}
                            onChange={(e) => setData('password', e.target.value)}
                        />


                        {errors.password &&
                            <Alert variant="danger">
                                {errors.password}
                            </Alert>
                        }
                    </Form.Group>
                </p>
                <p>
                    <Form.Group controlId="remember">
                        <Form.Check
                            type="Checkbox"
                            id="remember"
                            name="remember"
                            value={data.remember}
                            onChange={(e) => setData('remember', e.target.checked)}
                            label="remember me"
                        />
                    </Form.Group>
                </p>
                <Button className="d-block mx-auto my-3" type="submit">Log in</Button>
            </Form>
            {canResetPassword && (
                <p>
                    <Link
                        href={route('password.request')}
                        className="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Reset your password
                    </Link></p>
            )}

<p>Ask Sean, Trev, or Robin for help if needed.</p>

        </GuestLayout>
    );
}
